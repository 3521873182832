.katex {
    font-size: 2.5rem;
}

.card img {
    object-fit: contain;
    max-width: 100%;
    height: 300px;
}

ruby {
    color: #d8e2ef;
    #color: #061325;
    #opacity: 0.5;
    #color: rgba(0, 0, 0, 0.5);
    font-size: 1.5rem;
}

rt {
    opacity: 1.0;
}

hide {
    visibility: hidden;
}
